<template lang="html">
  <div class="">
    <NavigationGuide/>

    <div class="main">

      <div class="main_div"  >
        <h1 class="title" >
            Välkommen till vår guide
        </h1>
        <h2 class="text">
          {{ this.short_description }}
        </h2>
        <div class="text">
          Behöver du laga något. Klicka <span @click="to_explore()" :style="{textDecoration:'underline'}">här</span>
        </div>
      </div>
      <div class="main_subdiv"  >
        <div class="main_subdiv_sub"    v-for="(guide_object,idx) in searchablelist" :key="idx">
          <div class="main_subdiv_forloop" >
            <img
            :src="`${publicPath}`+ guide_object.image"
            :style="{width:'160px', marginRight:'4%',height:'160px'}" alt="">
            <div class="main_subdiv_sub_sub" >
              <h2 class="guide_headline" >
                {{guide_object.headline}}
              </h2>
              <h3 class="guide_text" >
                {{guide_object.text}}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>


  <bottombar/>

  </div>
</template>

<script>
 import bottombar from '../components/bottombar.vue';
 import NavigationGuide from '../components/Navigation_Guide.vue';
 import router from '../router/index'

export default {
  metaInfo: {
  title: 'Hitta reparatörer för dig',
  titleTemplate: '%s ',
  htmlAttrs: {
    lang: 'sv'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'description', content: 'Tips och råd för att hitta reparatörer för dig och få hjälp med att laga dina saker.' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components:{
    bottombar,NavigationGuide
  },
  data:function(){
    return{
      id:0,
      publicPath: process.env.BASE_URL +'',
      guides:[],
      name:"",
      searchablelist:[],
      short_description:""
    }
  },
  methods:{
    to_explore(){
      router.push({name:'explore_page'})
    },
    navigateToCategory(category) {
      this.$router.push({ name: 'guidesub', params: { category: category } });
    }
  },
    mounted(){
    this.id=0
    this.guides = [
      {
        "id":0,
        "name":"Hur reparationer sparar pengar och räddar miljön",
        "short_description":"Reparationer har blivit alltmer betydelsefulla i dagens samhälle, både när det gäller att spara pengar och minska vår miljöpåverkan. I den här artikeln kommer vi att utforska de positiva effekterna av att reparera datorer och möbler, och hur våra tjänster, inklusive datorreparation, möbeltapetsering, möbelreparation, datorservice och mobilreparation, kan hjälpa dig att dra nytta av dessa fördelar.",
        "text_list":[
          {"id":1, "image":"recycle.png" , "headline":"Miljövinsterna med reparation", "text":"Miljömedvetenhet är nu mer relevant än någonsin. Genom att välja att reparera istället för att kasta och köpa nytt kan du minska den negativa påverkan på vår planet. När det gäller elektronik, som datorer och mobiltelefoner, genererar tillverkningsprocessen stora mängder koldioxidutsläpp och avfall. Genom att reparera och förlänga livslängden på dina enheter bidrar du till att minska behovet av nya produkter och därmed minskar du utsläppen. Dessutom minskar det mängden elektroniskt avfall som hamnar på soptippen."},
          {"id":2,  "image":"moneyplant.jpg" , "headline":"Pengabesparingarna med reparation", "text":"Att investera i reparation istället för att köpa nytt kan vara ekonomiskt fördelaktigt. Datorer och möbler är ofta dyra, och reparation kan vara ett mer prisvärt alternativ. När din dator börjar visa tecken på problem, överväg att konsultera en professionell reparatör istället för att köpa en helt ny enhet. Det kan spara dig betydande summor pengar. Dessutom är kostnaden för att reparera vanliga möbelproblem som trasiga ben eller sönderdelad tapetsering ofta betydligt lägre än att köpa ny möbler"},
          {"id":3,"image":"tool2.jpg" , "headline":"Exempel på reparationer i Enjord", "text":"    Datorreparation: Våra erfarna tekniker kan diagnostisera och reparera olika datorproblem, inklusive hårdvarufel, virusinfektioner och uppdateringsbehov. Vi återställer din dator till full funktionalitet och förlänger dess livslängd. Möbeltapetsering och möbelreparation: Behöver dina möbler en uppdatering? Våra skickliga hantverkare kan återställa skönheten i dina möbler genom tapetsering och reparation av skador. Slipp dyra möbelköp och ge dina favoritmöbler nytt liv. Datorservice: Förebyggande underhåll är nyckeln till en långvarig dator. Vi erbjuder regelbunden datorservice för att säkerställa att din enhet fungerar smidigt och att potentiella problem upptäcks i tid. Mobilreparation: Reparation är ett miljövänligare alternativ än att köpa en ny mobiltelefon när din gamla enhet går sönder. Vi kan ersätta trasiga skärmar, byta ut batterier och lösa andra problem för att förlänga livslängden på din mobil."}
        ]
      },
    ]
    this.searchablelist=this.guides[this.id]['text_list']
    this.short_description=this.guides[this.id].short_description
    this.name =this.guides[this.id].name
  }

}
</script>

<style lang="css" scoped>

@media (max-width:450px ){
  .main{
    width:100%;
    height: auto;
    margin-top: 0%;
    padding-bottom: 80px;
    background-color: white

  }

  .title{
    flex-direction: column;
    justify-content: center;
    color: #0A6338;
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-family: sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px; /* 257.143% */
    text-transform: uppercase;
  }

  .main_div{
    /* flex-direction: column; */
    justify-content: center;
    width:100%;
    align-items:  center;
    background:rgba(10, 99, 56, 0.06);
    padding-bottom: 80px;
    text-align: center;
     margin: 0 auto;
     height: auto;
  }

  .text{
    color: #000;
      justify-content: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    width: 80%;
    margin-left: 10%;
    line-height: 121%; /* 21.78px */
  }

.main_subdiv_sub{
  width:100%;

}

  .main_subdiv_sub_sub{
      width:100%;
      height: auto;
      justify-content: center;

      align-items:  center;
  }

  .main_subdiv{
    /* flex-direction: column; */
    width:100%;
    justify-content: center;
    margin: 0 auto;

    align-items:  center;
    height:auto;
    margin-top: 4%;
    text-align: center;
  }

  .main_subdiv_forloop{
    justify-content: center;
    margin: 0 auto;

    align-items:  center;
    height:auto
  }

  .guide_headline{
    color: #0A6338;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 117.647% */
  text-transform: uppercase;
  }

  .guide_text{
    color: #000;
    font-family: sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 121%;
  }


}
@media (min-width:450px ){

  .main{
    width:100%;
    margin-top: 0%;
    padding-bottom: 80px;
    background-color: white

  }

.main_div{
  /* flex-direction: column; */
  justify-content: center;
  width:100%;
  align-items:  center;
  background:rgba(10, 99, 56, 0.06);
  padding-bottom: 80px;
  text-align: center;
     margin: 0 auto;
}
.main_subdiv_sub_sub{
    width:60%;
}

.main_subdiv{
  /* flex-direction: column; */
  width:100%;
  justify-content: center;
  text-align: center;
  margin: 0 auto;

  align-items:  center;
  height:auto;
  margin-top: 4%;
  text-align: center;
       margin: 0 auto;
}

.main_subdiv_forloop{
  justify-content: center;
  text-align: center;
  margin: 0 auto;

  align-items:  center;
display:flex;
height:270px
}

.title{
  flex-direction: column;
  justify-content: center;
  color: #0A6338;
  text-align: center;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  font-family: sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 108px; /* 257.143% */
  text-transform: uppercase;
}

.text{
  color: #000;
    justify-content: center;
  text-align: center;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 80%;
  margin-left: 10%;
  line-height: 121%; /* 21.78px */
}

.guide_headline{
  color: #0A6338;
font-family: sans-serif;
font-size: 34px;
text-align: left;
font-style: normal;
font-weight: 900;
line-height: 40px; /* 117.647% */
text-transform: uppercase;
}

.guide_text{
  text-align: left;
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 121%;
}
}
</style>
